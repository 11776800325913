<template>
  <div class="pool-status">
    <div class="item" v-for="item in timingArray">
      <img class="icon" :src="item.icon"/>
      <RowItem class="name">{{item.name}}</RowItem>
      <RowItem>
        <div>
          <div class="time-row">
            <div class="time clickable" @click="clickStartTime(item)">{{formatTimeValue(item.timeOnHour.value)}}:{{formatTimeValue(item.timeOnMinute.value)}}</div>
            <div class="time">-</div>
            <div class="time clickable" @click="clickEndTime(item)">{{formatTimeValue(item.timeOffHour.value)}}:{{formatTimeValue(item.timeOffMinute.value)}}</div>
          </div>
          <div class="switch-row">
            <div class="action" v-bind:class="{'action-selected':item.enable.value==1}">自动</div>
            <img class="icon clickable"  @click="clickSwitch(item.enable)"
                 :src="item.enable.value==1 ? require('@/assets/switch-left.png') : require('@/assets/switch-right.png')"/>
            <div class="action" v-bind:class="{'action-selected':item.enable.value==0}">手动</div>
          </div>
        </div>
      </RowItem>
    </div>

    <TimeDialog ref="timeDialog"></TimeDialog>
  </div>
</template>

<script>
import RowItem from "./RowItem";
import TimeDialog from "@/components/dialog/TimeDialog"
export default {
  components:{RowItem,TimeDialog},
  props: ["timingArray"],
  methods:{
    clickSwitch(item){
      let key = item.dataId
      let value = item.value==1 ? 0 : 1
      this.$emit("control",key,value)
    },
    formatTimeValue(value){
      if(!value){
        return "00"
      }
      return ("00"+value).substr(-2)
    },
    clickStartTime(item){
      let that = this
      this.$refs.timeDialog.show({
        title:item.name + "-启动",
        hour:item.timeOnHour.value,
        minute:item.timeOnMinute.value,
        onOk:(hour,minute)=>{
          that.$emit("control",item.timeOnHour.dataId,hour)
          that.$emit("control",item.timeOnMinute.dataId,minute)
        }
      })
    },
    clickEndTime(item){
      let that = this
      this.$refs.timeDialog.show({
        title:item.name + "-停止",
        hour:item.timeOffHour.value,
        minute:item.timeOffMinute.value,
        onOk:(hour,minute)=>{
          that.$emit("control",item.timeOffHour.dataId,hour)
          that.$emit("control",item.timeOffMinute.dataId,minute)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.pool-status {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .item{
    padding: 20px;
    margin: 10px;
    display: flex;
    flex-direction: column;

    .icon{
      width: 140px;
      height: 140px;
      object-fit: contain;
    }

    .name {
      text-align: center;
      font-size: 20px;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .time-row {
      display: flex;
      font-size: 30px;

      .time{
        margin-right: 5px;
      }
    }

    .switch-row {
      display: flex;
      align-items: center;

      .action {
        font-size: 20px;
      }

      .action-selected{
        color: #0084FF;
        font-weight: bold;
      }

      .icon {
        width: 80px;
        height: 30px;
        object-fit: contain;
      }
    }

  }
}
</style>
