<template>
  <div class="purification-mode">
    <div class="title-vert">{{ model.name }}</div>
    <div class="action">
      <div class="clickable" v-bind:class="{'action-selected':model.value==1}"
           @click="$emit('clickMode',model.dataId,1)">● {{ model.action1 }}</div>
      <br/>
      <div class="clickable" v-bind:class="{'action-selected':model.value==0}"
           @click="$emit('clickMode',model.dataId,0)">● {{ model.action2 }}</div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["model"]
}
</script>
<style lang="less" scoped>
.purification-mode {
  display: flex;
  align-items: center;

  .title-vert {
    font-size: 20px;
    line-height: 20px;
    border-left: 2px solid #888;
    border-right: 2px solid #888;
    width: 40px;
    text-align: center;
    margin-right: 10px;
    margin-left: 20px;
  }
  .action{
    font-size: 20px;
    margin-right: 20px;
  }

  .action-selected{
    color: #0084FF;
  }
}
</style>
