<template>
  <div class="purification">

    <template v-if="purificationData.timing&&purificationData.timing.length>0">
      <PurificationTiming v-for="item in purificationData.timing" :model="item" @control="control"></PurificationTiming>
    </template>

    <div style="display: flex">

      <template v-if="purificationData.control&&purificationData.control.length>0">
        <div class="title-vert">药泵启停控制</div>
        <div class="btn-item clickable" v-for="item in purificationData.control" @click="clickPump(item)">
          <img class="icon" :src="item.value==1 ? item.selectedIcon : item.icon"/>
          <div class="name" v-bind:class="{'name-selected':item.value==1}">{{ item.name }}</div>
        </div>
      </template>

      <template v-if="purificationData.mode&&purificationData.mode.length>0">
        <PurificationMode v-for="item in purificationData.mode" :model="item" @clickMode="control"></PurificationMode>
      </template>


    </div>


  </div>
</template>

<script>
import PurificationTiming from "./purification/PurificationTiming"
import PurificationMode from "./purification/PurificationMode"

export default {
  components: {PurificationTiming, PurificationMode},
  props: ["purificationData"],
  methods:{
    clickPump(item){
      let key = item.writeDataId
      let readKey = item.readDataId
      let value = item.value==1 ? 0 : 1
      this.$emit("controlItems", {
        [key]:value,
        [readKey]:value
      })
    },
    control(key,value){
      this.$emit("control",key,value)
    }
  }
}
</script>

<style lang="less" scoped>
.purification {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;

  .title-vert {
    font-size: 20px;
    line-height: 20px;
    border-left: 2px solid #888;
    border-right: 2px solid #888;
    width: 40px;
    text-align: center;
    margin-right: 10px;
  }

  .btn-item {
    margin-right: 20px;

    .icon {
      width: 100px;
      height: 100px;
      object-fit: contain;
    }

    .name {
      text-align: center;
      font-size: 20px;
    }

    .name-selected{
      color: #0084FF;
    }
  }
}
</style>
