<template>
  <a-modal v-model="visible" title="操作错误提示" @ok="handleOk">
    {{ content }}
  </a-modal>
</template>
<script>
export default {
  data() {
    return {
      visible: false,
      content: ""
    }
  },
  methods: {
    show(content) {
      this.content = content
      this.visible = true
      setTimeout(() => {
        this.visible = false
      }, 5000)
    },
    handleOk() {
      this.visible = false
    }
  }
}
</script>
<style lang="less" scoped>

</style>
