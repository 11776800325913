<template>
  <a-modal v-model="visible" title="设备告警" @ok="handleOk">
    {{content}}
  </a-modal>
</template>
<script>
export default {
  props:["valueMap","device"],
  data(){
    return {
      checkInterval:null,
      visible:false,
      content:""
    }
  },
  created() {
    console.log("fdasdfsadsf")
    this.checkInterval = setInterval(()=>{
      if(this.valueMap["309"]==1){
        this.content = "设备已停用!请与供应商索取密码."
        this.visible = true
      }else if(this.valueMap["310"]==1){
        this.content = "人机界面与PLC程式不配套!"
        this.visible = true
      }else if(this.valueMap["311"]==1){
        this.content = "循环水泵1过载故障!"
        this.visible = true
      }else if(this.valueMap["312"]==1){
        this.content = "循环水泵2过载故障!"
        this.visible = true
      }else if(this.valueMap["313"]==1){
        this.content = "循环水泵3过载故障!"
        this.visible = true
      }else if(this.valueMap["314"]==1){
        this.content = "循环水泵4过载故障!"
        this.visible = true
      }else if(this.valueMap["315"]==1){
        this.content = "循环水泵5过载故障!"
        this.visible = true
      }else if(this.valueMap["316"]==1){
        this.content = "设备房地面浸泡水!"
        this.visible = true
      }else{
        this.content = ""
        this.visible = false
      }

    },5000)
  },
  destroyed() {
    clearInterval(this.checkInterval)
  },
  methods:{
    handleOk(){
      this.visible=false
    }
  }
}
</script>
<style lang="less" scoped>

</style>
