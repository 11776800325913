<template>
  <div class="system-control">
<!--    选择备用泵-->
    <div class="item">
      <img class="icon" src="@/assets/system-pump.png"/>
      <RowItem class="name">选择备用泵</RowItem>
      <RowItem>
        <div class="value-row clickable" @click="clickSystemPump">
          <div class="number">{{systemControl.standbyPump.value}}</div>
          <div class="symbol">#</div>
        </div>
      </RowItem>
    </div>

    <!--  设备时间-->
    <div class="item">
      <img class="icon" src="@/assets/system-timing.png"/>
      <RowItem class="name">系统时钟</RowItem>
      <RowItem>
        <div class="value-row" >
          <div class="number">{{timeStr}}<br/>{{dateStr}}</div>
        </div>
      </RowItem>
    </div>

    <InputDialog ref="inputDialog"></InputDialog>
  </div>
</template>

<script>
import InputDialog from "@/components/dialog/InputDialog";
import RowItem from "./RowItem";
import moment from 'moment'

export default {
  components: {RowItem,InputDialog},
  props: ["systemControl"],
  computed: {
    timeStr(){
      return this.formatNumber(this.systemControl.deviceTime.hourValue) + ":" + this.formatNumber(this.systemControl.deviceTime.minuteValue)
    },
    dateStr(){
      return this.systemControl.deviceTime.yearValue + "-"
          + this.formatNumber(this.systemControl.deviceTime.monthValue) + "-"
          + this.formatNumber(this.systemControl.deviceTime.dayValue)
    }
  },
  methods:{
    clickSystemPump(){
      let that = this
      this.$refs.inputDialog.show({
        title:"选择备用泵",
        value:this.systemControl.standbyPump.value,
        onOk(value){
          that.$emit("control",that.systemControl.standbyPump.dataId,value)
        }
      })
    },
    clickAdjustTime(){
      // if(this.systemControl.deviceTime.enableValue==0){
      //   this.$message.warning("请先点击开始校准")
      //   return
      // }
      let that = this
      let timeFormat = "YYYY-MM-DD HH:mm"
      let time = moment().format(timeFormat)
      this.$confirm({
        title: '时间校准',
        content: '是否设置设备时间为 '+time,
        centered: true,
        onOk () {
          let date = new Date()
          let items = {
            [that.systemControl.deviceTime.yearDataId]:date.getFullYear(),
            [that.systemControl.deviceTime.monthDataId]:date.getMonth()+1,
            [that.systemControl.deviceTime.dayDataId]:date.getDate(),
            [that.systemControl.deviceTime.hourDataId]:date.getHours(),
            [that.systemControl.deviceTime.minuteDataId]:date.getMinutes(),
          }
          that.$emit("controlItems",items)
        }
      })
    },
    clickTimeSwitch(){
      let value = this.systemControl.deviceTime.enableValue==1?0:1
      this.$emit("control",this.systemControl.deviceTime.enableDataId,value)
    },
    formatNumber(value){
      return ("00"+value).substr(-2)
    }
  }
}
</script>

<style lang="less" scoped>
.system-control {
  display: flex;
  justify-content: flex-end;

  .item {
    padding: 20px;
    margin: 10px;
    display: flex;
    flex-direction: column;

    .icon {
      width: 140px;
      height: 140px;
      object-fit: contain;
    }

    .name {
      text-align: center;
      font-size: 20px;
      margin-top: 10px;
      margin-bottom: 10px;
    }


    .value-row{
      display: flex;
      border-bottom: 1px solid #000;
      height: 70px;
      align-items: flex-end;
      font-size: 22px;
      width: 140px;
      .number{
        flex: 1;
      }
      .stop-use{
        font-size: 12px;
        line-height: 16px;
      }
      .switch-icon{
        width: 80px;
        height: 30px;
        object-fit: contain;
      }
    }
  }
}
</style>
