<template>
  <div class="pool-calibration">
    <div class="item" v-for="item in calibrationArray">
      <div class="name">{{ item.name }}探头<br/>三点校准</div>

      <div class="data-item" v-for="(dataItem,index) in valueNameArray" v-bind:class="{clickable:index<6}" @click="clickItem(item,index)">
        <div class="value">{{item.valueArray[index]}}</div>
        <div class="title" v-bind:style="{background:item.color}">{{dataItem}}</div>
      </div>

      {{item.resetValue}}

      <div class="recover clickable" v-bind:style="{background:item.color}" @click="clickReset(item)">恢复出厂<br/>设置状态</div>

    </div>

    <InputDialog ref="inputDialog"></InputDialog>
  </div>
</template>

<script>
import InputDialog from "@/components/dialog/InputDialog";
export default {
  components:{InputDialog},
  props: ["calibrationArray"],
  computed: {
    valueNameArray() {
      return [
        "采样值一",
        "标准值一",
        "采样值二",
        "标准值二",
        "采样值三",
        "标准值三",
        "三点采样值",
        "当前电极"
      ]
    },
  },
  methods:{
    clickItem(item,index){
      if(index>=6){
        return
      }
      let that = this
      let dataId = item.dataIdArray[index]
      let value = item.valueArray[index]
      let title = this.valueNameArray[index]
      this.$refs.inputDialog.show({
        title:item.name + "探头三点校准 - " + title,
        value:value,
        onOk(value){
          that.$emit("control",dataId,value)
        }
      })
    },
    clickReset(item){
      let that = this
      this.$confirm({
        title: item.name + "探头三点校准",
        content: '是否恢复出厂设置状态',
        centered: true,
        onOk () {
          that.$emit("control",item.resetDataId,1)
        }
      })
    },
  }
}
</script>

<style lang="less" scoped>
.pool-calibration {
  .item{
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;

    .name{
      width: 100px;
      text-align: right;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      margin-right: 5px;
      font-size: 16px;
      color: #666;
    }

    .data-item{
      background: #C8C8C9;
      margin-right: 2px;
      text-align: center;

      .value{
        font-size: 30px;
      }

      .title{
        background: #992;
        width: 100px;
        color: #FFF;
        padding: 2px;
      }
    }

    .recover{
      background: #999;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 30px;
      color: #FFF;
    }
  }
}
</style>
