<template>
  <div class="pool-bottom-bar">

    <img class="product-icon" :src="productIcon"/>

    <div style="flex-grow: 1"></div>

    <div class="item clickable" v-for="item in tabData" @click="$emit('onTabChange',item.key)">
      <img class="icon" :src="tabKey==item.key ? item.selectedIcon : item.icon"/>
      <div class="name" v-bind:class="{'name-selected':tabKey==item.key}">{{ item.name }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["device", "tabKey", "tabData"],
  computed: {
    productIcon() {
      let icons = {
        "ICS_1": require("@/assets/version_1.png"),
        "ICS_2": require("@/assets/version_2.png"),
        "ICS_3": require("@/assets/version_3.png"),
        "ICS_PRO": require("@/assets/version_pro.png"),
        "ICS_MAX": require("@/assets/version_max.png"),
      }
      return icons[this.device.model]
    },
    switchArray() {
      return [
        {
          icon: require("@/assets/tab-status.png"),
          selectedIcon: require("@/assets/tab-status-selected.png"),
          name: "泳池状态",
          key: "status"
        },
        {
          icon: require("@/assets/tab-purification.png"),
          selectedIcon: require("@/assets/tab-purification-selected.png"),
          name: "水质净化",
          key: "purification"
        },
        {
          icon: require("@/assets/tab-sand-tank.png"),
          selectedIcon: require("@/assets/tab-sand-tank-selected.png"),
          name: "砂缸清洗",
          key: "sand-tank"
        },
        {
          icon: require("@/assets/tab-timing.png"),
          selectedIcon: require("@/assets/tab-timing-selected.png"),
          name: "定时控制",
          key: "timing-control"
        },
        {
          icon: require("@/assets/tab-system.png"),
          selectedIcon: require("@/assets/tab-system-selected.png"),
          name: "系统设置",
          key: "system-control"
        },
        {
          icon: require("@/assets/tab-calibration.png"),
          selectedIcon: require("@/assets/tab-calibration-selected.png"),
          name: "探头校准",
          key: "calibration"
        },
        {
          icon: require("@/assets/tab-history.png"),
          selectedIcon: require("@/assets/tab-history-selected.png"),
          name: "历史数据",
          key: "history-data"
        },
        {
          icon: require("@/assets/tab-device-lock.png"),
          selectedIcon: require("@/assets/tab-device-lock-selected.png"),
          name: "锁定设备",
          key: "device-lock"
        },
        {
          icon: require("@/assets/tab-water-alarm.png"),
          selectedIcon: require("@/assets/tab-water-alarm-selected.png"),
          name: "水质报警",
          key: "water-alarm"
        }
      ]
    }
  }
}
</script>

<style lang="less" scoped>
.pool-bottom-bar {
  display: flex;
  align-items: center;
  background: #CCC;
  padding: 40px 80px;

  .product-icon {
    width: 120px;
    height: 120px;
    object-fit: contain;
  }

  .item {
    margin-left: 40px;

    .icon {
      width: 80px;
      height: 80px;
    }

    .name {
      text-align: center;
      font-size: 16px;
      color: #666;
    }

    .name-selected {
      color: #0084FF;
    }
  }
}
</style>
