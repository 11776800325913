<template>
  <page-content>

    <common-table
        ref="table"
        path="web/iot/device/error-log"
        date-range
        :queryParams="queryParams"
        :columns="columns">

      <template slot="search">
        <a-button @click="$refs.table.export('报警记录')">导出Excel</a-button>
      </template>

      <template slot="type" slot-scope="{record}">
        <a-tag v-if="record.type==1" color="gray">低于最小值</a-tag>
        <a-tag v-else color="cyan">高于最大值</a-tag>
      </template>

    </common-table>


  </page-content>
</template>
<script>
const errorDes = {
  "309":"设备已停用!请与供应商索取密码.",
  "310":"人机界面与PLC程式不配套!",
  "311":"循环水泵1过载故障!",
  "312":"循环水泵2过载故障!",
  "313":"循环水泵3过载故障!",
  "314":"循环水泵4过载故障!",
  "315":"循环水泵5过载故障!",
  "316":"设备房地面浸水开关报警!",
}
export default {

  data() {
    return {
      queryParams: {
        deviceId: this.$route.params.deviceId || null
      },

    }
  },

  computed: {
    columns() {
      return [
        {
          title: '时间',
          dataIndex: 'createdAt',
          sorter: true
        },
        {
          title: '设备名称',
          dataIndex: 'nickName',
        },
        {
          title: '设备编号',
          dataIndex: 'iotId',
        },
        {
          title: '数据点',
          dataIndex: 'type',
        },
        {
          title: '故障',
          dataIndex: 'error',
          customRender(text,record){
            return errorDes[record.type] || record.type
          }

        },
      ]
    }
  },
  methods: {
    getList() {
      this.$refs.table.getData()
    }

  }
}
</script>
<style lang="less" scoped>

</style>
