<template>
  <div class="row-item">
    <div class="row-tag"></div>
    <slot></slot>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.row-item{
  display: flex;

  .row-tag{
    width: 15px;
    border-top: 1px solid #333;
    border-left: 1px solid #333;
  }
}
</style>
