<template>
  <div class="purification-timing">
    <div>
      <div class="row">
        <div class="title">{{ model.name }}</div>
        <img class="action clickable" src="@/assets/ic_sub.png" @click="clickSub"/>
        <div class="text-time clickable" style="width: 100px;text-align: center" @click="clickSetValue">
          {{ model.set.value || "/" }}
        </div>
        <img class="action clickable" src="@/assets/ic_add.png" @click="clickAdd"/>
      </div>
      <div style="height: 10px;"></div>
      <div class="row">
        <div class="title">间断加药</div>

        <div class="row" v-bind:class="{'status-blink':model.start.statusValue==1}">
          <div class="text">加</div>
          <div class="text-time clickable" @click="clickTime(model.start)">{{ formatTimeValue(model.start.value) }}</div>
          <div class="text-unit">分<br/>钟</div>
        </div>

        <div class="row" v-bind:class="{'status-blink':model.stop.statusValue==1}">
          <div class="text">停</div>
          <div class="text-time clickable" @click="clickTime(model.stop)">{{ formatTimeValue(model.stop.value) }}</div>
          <div class="text-unit">分<br/>钟</div>
        </div>

      </div>
    </div>

    <InputDialog ref="inputDialog"></InputDialog>

  </div>
</template>

<script>
import InputDialog from "@/components/dialog/InputDialog";

export default {
  components: {InputDialog},
  props: ["model"],
  methods: {
    formatTimeValue(value) {
      if (!value) {
        return "00"
      }
      return ("00" + value).substr(-2)
    },
    clickSub() {
      let value = parseFloat(this.model.set.value)
      if (value > 0) {
        this.control(this.model.set.dataId, value - this.model.set.divider)
      }
    },
    clickAdd() {
      let value = parseFloat(this.model.set.value)
      this.control(this.model.set.dataId, value + this.model.set.divider)
    },
    clickSetValue() {
      let that = this
      this.$refs.inputDialog.show({
        title: this.model.name,
        onOk(value) {
          that.control(that.model.set.dataId, value)
        }
      })
    },
    clickTime(item) {
      let that = this
      this.$refs.inputDialog.show({
        title: item.name,
        value: item.value,
        onOk(value) {
          that.control(item.dataId, value)
        }
      })
    },

    control(key, value) {
      this.$emit("control", key, value)
    }
  }
}
</script>

<style lang="scss" scoped>
.purification-timing {
  display: flex;
  padding: 30px 0;
  align-items: center;
  margin-left: 20px;

  .row {
    display: flex;
    align-items: center;
  }

  .title {
    border-left: 2px solid #888;
    border-right: 2px solid #888;
    width: 60px;
    font-size: 20px;
    line-height: 22px;
    text-align: center;
    margin-right: 10px;
  }

  .action {
    width: 40px;
    height: 40px;
    background: #F0F0F0;
    padding: 5px;
  }

  .text {
    font-size: 28px;
  }

  .text-time {
    font-size: 40px;
    text-align: center;
    padding: 0 5px;
  }

  .text-unit {
    font-size: 14px;
    line-height: 14px;
    margin-right: 20px;
    padding-top: 2px;
  }
}

@keyframes blink {
  0% {
    opacity: 0.2
  }
  //50%  {opacity: 1}
  100% {
    opacity: 1
  }
}

.status-blink {
  animation-iteration-count: infinite;
  animation-name: blink;
  animation-duration: 2s;
}
</style>
