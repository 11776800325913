<template>
  <a-modal v-model="visible" :title="option.title" @ok="handleOk">
    <div class="time-row">
      <a-input class="input" v-model="hour" ></a-input>
      <div>时</div>
      <a-input class="input" v-model="minute" ></a-input>
      <div>分</div>
    </div>

  </a-modal>
</template>

<script>
  export default {
    data() {
      return {
        visible: false,
        option: {},
        hour:"",
        minute:""
      }
    },
    methods: {
      show(option) {
        this.option = option
        this.hour = option.hour
        this.minute = option.minute
        this.visible = true
      },
      handleOk() {
        this.visible = false
        let time = this.hour * 60 + this.minute
        this.option.onOk(this.hour,this.minute)

      }
    }
  }
</script>

<style scoped lang="less">
.time-row{
  display: flex;
  align-items: center;

  div{
    margin-left: 10px;
    margin-right: 20px;
    font-weight: 500;
  }
}
</style>
