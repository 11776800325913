<template>
  <div class="sand-tank">
    <div class="item" v-for="(item,index) in sandTankArray">
      <div class="icon-box">
        <img class="icon" src="@/assets/ic_sand.png"/>
        <div class="index">{{index+1}}</div>
      </div>

      <RowItem class="name">
        <div class="flex-fill">{{ item.name }} </div>
        <div class="status">{{["正常过滤","反冲洗中","底管清洗"][item.statusValue]}}</div>
      </RowItem>
      <RowItem>
        <div class="switch-row">
          <div class="action" v-bind:class="{'action-selected':item.switchValue==0}">过滤</div>
          <img class="icon clickable"  @click="clickSwitch(item)"
               :src="item.switchValue==0 ? require('@/assets/switch-left.png') : require('@/assets/switch-right.png')"/>
          <div class="action" v-bind:class="{'action-selected':item.switchValue==1}">反洗</div>
        </div>
      </RowItem>

      <RowItem class="name">
        <div>反冲洗</div>
        <div class="time clickable" @click="clickTimeCX(item)">{{item.chongxiValue}}</div>
        <div>分钟</div>
      </RowItem>

      <RowItem class="name">
        <div>底管清洗</div>
        <div class="time clickable" @click="clickTimeDG(item)">{{item.diguanValue}}</div>
        <div>分钟</div>
      </RowItem>

    </div>

    <InputDialog ref="inputDialog"></InputDialog>
    <DeviceWarningModal ref="warningModal"></DeviceWarningModal>

  </div>
</template>

<script>
import RowItem from "./RowItem";
import InputDialog from "@/components/dialog/InputDialog";
import DeviceWarningModal from "@/views/device/_components/DeviceWarningModal";
export default {
  components: {RowItem,InputDialog,DeviceWarningModal},
  props: ["sandTankArray","valueMap"],
  methods:{
    clickSwitch(item){
      let key = item.switchDataId
      let value = item.switchValue==1 ? 0 : 1

      if(value==1){
        if(this.valueMap["82"]==0 && this.valueMap["83"]==0 && this.valueMap["84"]==0 && this.valueMap["85"]==0 && this.valueMap["86"]==0){
          this.$refs.warningModal.show("循环水泵未启动沙缸不能执行反冲洗")
          return
        }
      }

      this.$emit("control",key,value)
    },
    clickTimeCX(item){
      let that = this
      this.$refs.inputDialog.show({
        title:item.name + "反冲洗时间",
        onOk(value){
          that.$emit("control",item.chongxiDataId,value)
        }
      })
    },
    clickTimeDG(item){
      let that = this
      this.$refs.inputDialog.show({
        title:item.name + "底管清洗时间",
        onOk(value){
          that.$emit("control",item.diguanDataId,value)
        }
      })
    },
  }
}
</script>

<style lang="less" scoped>
.sand-tank {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .item {
    padding: 20px;
    margin-left: 20px;

    .icon-box{
      width: 160px;
      height: 160px;
      position: relative;

      .icon {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      .index{
        bottom: 10px;
        text-align: center;
        position: absolute;
        width: 100%;
        color: #333;
        font-size: 20px;
      }

    }

    .name {
      text-align: left;
      font-size: 20px;
      margin-top: 10px;
      margin-bottom: 10px;

      .time{
        flex-grow: 1;
        border-bottom: 1px solid #333;
        text-align: center;
        margin: 0 5px;
        height: 32px;
      }
    }

    .switch-row {
      display: flex;
      align-items: center;

      .action {
        font-size: 20px;
      }

      .action-selected{
        color: #0084FF;
        font-weight: bold;
      }

      .icon {
        width: 80px;
        height: 30px;
        object-fit: contain;
      }
    }

  }
}
</style>
