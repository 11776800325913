<template>
  <a-modal v-model="visible" :title="option.title" @ok="handleOk">
    <div class="time-row">
      <a-input class="input" v-model="year" ></a-input>
      <div>年</div>
      <a-input class="input" v-model="month" ></a-input>
      <div>月</div>
      <a-input class="input" v-model="day" ></a-input>
      <div>日</div>
    </div>

  </a-modal>
</template>

<script>
  export default {
    data() {
      return {
        visible: false,
        option: {},
        year:"",
        month:"",
        day:""
      }
    },
    methods: {
      show(option) {
        this.option = option
        this.year = option.year
        this.month = option.month
        this.day = option.day
        this.visible = true
      },
      handleOk() {
        this.visible = false
        this.option.onOk(this.year,this.month,this.day)
      }
    }
  }
</script>

<style scoped lang="less">
.time-row{
  display: flex;
  align-items: center;

  div{
    margin-left: 10px;
    margin-right: 20px;
    font-weight: 500;
  }
}
</style>
