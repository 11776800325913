<template>
  <div class="system-control">
<!--    运行总计时间-->
    <div class="item">
      <div class="icon"></div>
      <RowItem class="name">运行总计时间</RowItem>
      <RowItem>
        <div class="value-row">
          <div class="number">{{model.deviceRunTime.dayValue}}</div>
          <div class="symbol">天</div>
          <div class="number">{{model.deviceRunTime.hourValue}}</div>
          <div class="symbol">小时</div>
        </div>
      </RowItem>
    </div>

    <!--    时间校准-->
    <div class="item">
      <img class="icon" src="@/assets/system-timing.png"/>
      <RowItem class="name">时间校准</RowItem>
      <RowItem>
        <div class="value-row" style="width: 320px" >
          <div class="number " style="text-align: left">
            <span class="time-value" @click="adjustTime('时',model.timeSync.hourDataId,model.timeSync.hourValue)">{{formatNumber(model.timeSync.hourValue)}}</span> :
            <span class="time-value" @click="adjustTime('分',model.timeSync.minuteDataId,model.timeSync.minuteValue)">{{formatNumber(model.timeSync.minuteValue)}}</span><br/>
            <span class="time-value" @click="adjustTime('年',model.timeSync.yearDataId,model.timeSync.yearValue)">{{formatNumber(model.timeSync.yearValue)}}</span> -
            <span class="time-value" @click="adjustTime('月',model.timeSync.monthDataId,model.timeSync.monthValue)">{{formatNumber(model.timeSync.monthValue)}}</span> -
            <span class="time-value" @click="adjustTime('日',model.timeSync.dayDataId,model.timeSync.dayValue)">{{formatNumber(model.timeSync.dayValue)}}</span>
          </div>

          <div class="stop-use">保存<br/>校准</div>
          <img class="switch-icon clickable" @click="clickTimeSwitch" :src="model.timeSync.enableValue==0 ? require('@/assets/switch-left.png') : require('@/assets/switch-right.png') "/>
          <div class="stop-use">开始<br/>校准</div>
        </div>
      </RowItem>
    </div>


    <!--    锁机计时-->
    <div class="item">
      <img class="icon" src="@/assets/system-lock.png"/>
      <RowItem class="name">运行停用时间</RowItem>
      <RowItem>
        <div class="value-row clickable" style="width: 240px" @click="clickLockTime">
          <div class="number" style="padding: 0 10px">{{model.deviceLockTime.yearValue}}</div>
          <div class="symbol">年</div>
          <div class="number">{{model.deviceLockTime.monthValue}}</div>
          <div class="symbol">月</div>
          <div class="number">{{model.deviceLockTime.dayValue}}</div>
          <div class="symbol">日</div>
        </div>
      </RowItem>
    </div>

    <!--    锁停设备-->
    <div class="item">
      <div class="icon"></div>
      <RowItem class="name">锁停设备</RowItem>
      <RowItem>
        <div class="value-row clickable" @click="clickStop">
          <div class="stop-use">停止<br/>使用</div>
          <img class="switch-icon " :src="model.deviceLockStatus.value==1 ? require('@/assets/switch-left.png') : require('@/assets/switch-right.png') "/>
          <div class="stop-use"></div>
        </div>
      </RowItem>
    </div>

    <DateDialog ref="dateDialog"></DateDialog>
    <InputDialog ref="inputDialog"></InputDialog>
  </div>
</template>

<script>
import DateDialog from "@/components/dialog/DateDialog";
import RowItem from "./RowItem";
import moment from "moment";

export default {
  components: {RowItem,DateDialog},
  props: ["model"],
  computed: {
    timeStr(){
      return this.formatNumber(this.model.timeSync.hourValue) + ":" + this.formatNumber(this.model.timeSync.minuteValue)
    },
    dateStr(){
      return this.model.timeSync.yearValue + "-"
          + this.formatNumber(this.model.timeSync.monthValue) + "-"
          + this.formatNumber(this.model.timeSync.dayValue)
    }
  },
  methods:{
    clickLockTime(){
      let that = this
      this.$refs.dateDialog.show({
        title:"运行停用时间",
        year:this.model.deviceLockTime.yearValue,
        month:this.model.deviceLockTime.monthValue,
        day:this.model.deviceLockTime.dayValue,
        onOk(year,month,day){
          let items = {}
          items[that.model.deviceLockTime.yearDataId] = year
          items[that.model.deviceLockTime.monthDataId] = month
          items[that.model.deviceLockTime.dayDataId] = day
          that.$emit("controlItems",items)
        }
      })
    },
    clickStop(){
      let that = this
      let value = this.model.deviceLockStatus.value
      let title = value==1 ? "是否取消设备锁停" : "是否开启设备锁停开关"
      this.$confirm({
        title: title,
        content: ' ',
        centered: true,
        onOk () {
          that.$emit("control",that.model.deviceLockStatus.dataId, value==1?0:1)
        }
      })
    },
    clickAdjustTime(){
      // if(this.model.timeSync.enableValue==0){
      //   this.$message.warning("请先点击开始校准")
      //   return
      // }
      let that = this
      let timeFormat = "YYYY-MM-DD HH:mm"
      let time = moment().format(timeFormat)
      this.$confirm({
        title: '时间校准',
        content: '是否设置设备时间为 '+time,
        centered: true,
        onOk () {
          let date = new Date()
          let items = {
            [that.model.timeSync.yearDataId]:date.getFullYear(),
            [that.model.timeSync.monthDataId]:date.getMonth()+1,
            [that.model.timeSync.dayDataId]:date.getDate(),
            [that.model.timeSync.hourDataId]:date.getHours(),
            [that.model.timeSync.minuteDataId]:date.getMinutes(),
          }
          that.$emit("controlItems",items)
        }
      })
    },
    adjustTime(title,dataId,value){
      let that = this
      this.$refs.inputDialog.show({
        title:"时间校准 - " + title,
        value,
        onOk(value){
          that.$emit("control",dataId,value)
        }
      })
    },
    clickTimeSwitch(){
      let value = this.model.timeSync.enableValue==1?0:1
      this.$emit("control",this.model.timeSync.enableDataId,value)
    },
    formatNumber(value){
      return ("00"+value).substr(-2)
    }
  }
}
</script>

<style lang="less" scoped>
.system-control {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .item {
    padding: 20px;
    margin: 10px;
    display: flex;
    flex-direction: column;

    .icon {
      width: 140px;
      height: 140px;
      object-fit: contain;
    }

    .name {
      text-align: center;
      font-size: 20px;
      margin-top: 10px;
      margin-bottom: 10px;
    }


    .value-row{
      display: flex;

      height: 70px;
      align-items: flex-end;
      font-size: 22px;
      width: 160px;
      .number{
        flex: 1;
        text-align: center;
        border-bottom: 1px solid #000;
      }
      .stop-use{
        font-size: 12px;
        line-height: 16px;
      }
      .switch-icon{
        width: 80px;
        height: 30px;
        object-fit: contain;
      }
    }
  }

  .time-value{
    cursor: pointer;
    padding: 2px 5px;
    border-radius: 5px;
  }
  .time-value:hover{
    background: #F0F0F0;

  }
}
</style>
