<template>
  <div class="pool-paras">

    <!--    实时数据，水温，PH，ORP等 -->
      <div class="para-item" v-for="item in paraArray">
        <RowItem class="para-name">&nbsp;{{ item.name }} - {{item.unit}}</RowItem>
        <RowItem>
          <div class="value">{{item.value}}</div>
<!--          <div class="unit-box">-->
<!--            <img src="@/assets/ic_unit.png"/>-->
<!--            <div class="unit">{{item.unit}}</div>-->
<!--          </div>-->
        </RowItem>
      </div>


  </div>
</template>

<script>
import RowItem from "./RowItem"
export default {
  components:{RowItem},
  props: ["paraArray"],
}
</script>

<style lang="less" scoped>
.pool-paras {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 20px 0;

  .para-item{
    margin-left: 50px;
    .para-name{
      font-size: 20px;
      margin-bottom: 5px;
    }

    .value{
      font-size: 80px;
      height: 80px;
      line-height: 80px;
      color: #0084FF;
    }

    .unit-box{
      position: relative;
      width: 35px;
      height: 35px;
      align-self: flex-end;
      margin:10px;
      opacity: 0.7;

      img{
        width: 100%;
        height: 100%;
        position: absolute;
        object-fit: contain;
      }

      .unit{
        width: 100%;
        height: 100%;
        line-height: 34px;
        position: absolute;
        color: #FFF;
        text-align: center;
        font-size: 12px;
      }
    }
  }

}
</style>
